$white: #EDF0F5;
$yellow: #EBFF00;
$dark-blue: #344EAD;
$light-blue: #6F96D1;
$black: #091F5B;
$row-number: 20;
$column-number: 10;
$cell-width: 45px;
$cell-height: 28px;

#root {
  height: 100vh;
  background: transparent;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0;
  padding: 0;
}

.page {
  height: 100vh;
}

.bracket{
  height: 50px;
  position: relative;
  top: 10px;
  margin: 0 5px;
}

.body {
  display: grid;
  grid-template-columns: 1fr calc($column-number * $cell-width) 1fr;
  grid-template-areas: 'right board left';
  width: 100vw;
  justify-items: center;
  place-self: start;
  column-gap: 50px;
}

.board {
  grid-area: board;
  display: grid;
  place-items: stretch;
  grid-auto-flow: column;
  background-color: $black;
  grid-template-columns:  repeat($column-number, $cell-width);
  grid-template-rows: repeat($row-number, $cell-height);
  border: 2px solid $white;
  margin: 10px;
}

.board:focus {
  outline: none;
}

.right-side {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  color: $yellow;
  font-size: 25px;
  font-weight: 300;
  text-transform: uppercase;
}

.left-side {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: repeat(2, 1fr);
  color: $yellow;
  font-size: 25px;
  font-weight: 300;
  text-transform: uppercase;
  justify-self: start;
  margin-bottom: 10px;
}

.score {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 30px 45px 30px 45px;
  grid-template-areas:
          'points'
          'point-value'
          'level'
          'level-value'
;
  align-self: center;
  margin: 10px;
}

.points {
  grid-area: points;
}

.level{
  grid-area: level;
}

.point-value, .level-value {
  text-transform: none;
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 10px;
  align-self: end;
}

.point-value{
  grid-area: point-value;
}

.level-value {
  grid-area: level-value;
}

.buttons {
  box-sizing: border-box;
  display: grid;
  height: 83px;
  width: 126px;
  margin: 15px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
          '. up .'
          'left down right' ;
  align-self: end;
  justify-self: start;
  column-gap: 7px;
  row-gap: 3px;
}

.button {
  height: 40px;
  width: 40px;
  border: 2px solid $yellow;
  border-radius: 8px;
  display: grid;
  place-items: stretch;
  cursor: pointer;
}

.up {
  grid-area: up;
  .arrow{
    position: relative;
    bottom: -8px;
  }
}

.down {
  grid-area: down;

  .arrow{
    position: relative;
    bottom: 8px;
  }
}

.left {
  grid-area: left;
}

.right {
  grid-area: right;
}

.t-row {
  display: inline-grid;
  grid-template-columns: repeat($column-number, $cell-width);
}

.t-cell {
  display: inline-block;
  height: $cell-height;
  width: $cell-width;
}

.t-cell-s {
  background-image: url("assets/smallTote.png");
  background-size: contain;
}

.t-cell-b1 {
  background-image: url("assets/topBigTote.png");
  background-size: contain;
}

.t-cell-b2{
  background-image: url("assets/bottomBigTote.png");
  background-size: contain;
}

.game-over, .start-game {
  grid-area: board;
  height: calc($row-number * $cell-height);
  width: calc($column-number * $cell-width);
  z-index: 2;
  background-color: rgba(237, 240, 245, 0.7);
  color: $dark-blue;
  font-size: 50px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  box-sizing: border-box;
  margin-top: 12px;
}

.start-game {
  background-color: rgba(237, 240, 245, 0.7);
}

.game-over-content, .start-game-content {
  place-self: center;
  text-align: center;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
  'header'
  'body'
  'button'
;
  .game-over-button, .start-game-button {
    color: $black;
    font-size: 50px;
    text-align: center;
    text-transform: uppercase;
    justify-self: center;
    align-self: center;
    cursor: pointer;
  }
}

.game-over-header, .start-game-header {
  color: $yellow;
}

.game-over-text {
  font-size: 35px;
}

@media screen and (max-width: 700px) {
  #root {
    height: 100vh;
  }
  $board-height: calc(calc($row-number * 19px) + 60px);
  $board-width: calc(calc($column-number * 30px) + 20px);

  .body {
    display: grid;
    grid-auto-flow: row;
    align-content: start;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 0 $board-height 200px;
    grid-template-areas:
            'right'
            'board'
            'left'
  ;
    width: 100vw;
    justify-items: center;
  }

  .board, .game-over, .start-game {
    grid-area: board;
    height: calc($row-number * 19px);
    width: calc($column-number * 30px);
    margin: 32px;
  }

  .board {
    grid-template-columns:  repeat($column-number, 30px);
    grid-template-rows: repeat($row-number, 19px);
    margin: 30px;
  }

  .start-game-header {
    font-size: 45px;
  }

  .t-cell{
    height: 19px;
    width: 30px;
  }
  .t-row {
    display: inline-grid;
    grid-template-columns: repeat($column-number, 30px);
  }

  .buttons{
    align-self: flex-end;
  }

  .left-side {
    grid-area: left;
    grid-auto-flow: row;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
    font-size: 20px;
    place-self: center;
  }
  .point-value, .level-value {
    margin-bottom: 10px;
    align-self: center;
    justify-self: start;
  }

  .score {
    grid-auto-flow: row;
    grid-template-rows: repeat(2, 30px);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
          'points level'
          'point-value level-value';
    column-gap: 30px;
  }

}

@media screen and (max-height: 680px) {
  #root {
    height: 100vh;
  }
  $board-height: calc(calc($row-number * 19px) + 20px);
  $board-width: calc(calc($column-number * 30px) + 20px);

  .body {
    display: grid;
    grid-auto-flow: row;
    align-content: start;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 0 $board-height 1fr;
    grid-template-areas:
            'right'
            'board'
            'left'
  ;
    width: 100vw;
    justify-items: center;
  }

  .board, .game-over, .start-game {
    grid-area: board;
    height: calc($row-number * 19px);
    width: calc($column-number * 30px);
    margin: 12px;
  }

  .board {
    grid-template-columns:  repeat($column-number, 30px);
    grid-template-rows: repeat($row-number, 19px);
    margin: 10px;
  }

  .start-game-header {
    font-size: 45px;
  }

  .t-cell{
    height: 19px;
    width: 30px;
  }
  .t-row {
    display: inline-grid;
    grid-template-columns: repeat($column-number, 30px);
  }

  .buttons{
    align-self: flex-end;
    margin: 0 15px;
  }

  .left-side {
    grid-area: left;
    grid-auto-flow: row;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
    font-size: 20px;
    place-self: center;
  }
  .point-value, .level-value {
    margin-bottom: 10px;
    align-self: center;
    justify-self: start;
  }

  .score {
    grid-auto-flow: row;
    grid-template-rows: repeat(2, 30px);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
          'points level'
          'point-value level-value';
    column-gap: 30px;
  }
}